/*
 * The Typekit service used to deliver this font or fonts for use on websites
 * is provided by Adobe and is subject to these Terms of Use
 * http://www.adobe.com/products/eulas/tou_typekit. For font license
 * information, see the list below.
 *
 * roc-grotesk:
 *   - http://typekit.com/eulas/00000000000000007735b7bc
 *   - http://typekit.com/eulas/00000000000000007735b7c0
 *   - http://typekit.com/eulas/00000000000000007735b7c3
 *   - http://typekit.com/eulas/00000000000000007735b7c5
 *   - http://typekit.com/eulas/00000000000000007735b7c8
 *   - http://typekit.com/eulas/00000000000000007735b7cc
 *   - http://typekit.com/eulas/00000000000000007735b7d0
 *   - http://typekit.com/eulas/00000000000000007735b7d4
 *   - http://typekit.com/eulas/00000000000000007735b7da
 *
 * © 2009-2022 Adobe Systems Incorporated. All Rights Reserved.
 */
/*{"last_published":"2022-06-17 21:07:22 UTC"}*/

/* @import url("https://p.typekit.net/p.css?s=1&k=out1jep&ht=tk&f=41077.41078.41097.41098.41108.41109.41110.41111.41112&a=98453951&app=typekit&e=css"); */

@font-face {
font-family:"roc-grotesk";
src:url("./fonts/roc-grotesk/roc-grotesk_800.woff2") format("woff2"),url("./fonts/roc-grotesk/roc-grotesk_800.woff") format("woff"),url("./fonts/roc-grotesk/roc-grotesk_800.otf") format("opentype");
font-display:auto;font-style:normal;font-weight:800;font-stretch:normal;
}

@font-face {
font-family:"roc-grotesk";
src:url("./fonts/roc-grotesk/roc-grotesk_600.woff2") format("woff2"),url("./fonts/roc-grotesk/roc-grotesk_600.woff") format("woff"),url("./fonts/roc-grotesk/roc-grotesk_600.otf") format("opentype");
font-display:auto;font-style:normal;font-weight:600;font-stretch:normal;
}

@font-face {
font-family:"roc-grotesk";
src:url("./fonts/roc-grotesk/roc-grotesk_700.woff2") format("woff2"),url("./fonts/roc-grotesk/roc-grotesk_700.woff") format("woff"),url("./fonts/roc-grotesk/roc-grotesk_700.otf") format("opentype");
font-display:auto;font-style:normal;font-weight:700;font-stretch:normal;
}

@font-face {
font-family:"roc-grotesk";
src:url("./fonts/roc-grotesk/roc-grotesk_200.woff2") format("woff2"),url("./fonts/roc-grotesk/roc-grotesk_200.woff") format("woff"),url("./fonts/roc-grotesk/roc-grotesk_200.otf") format("opentype");
font-display:auto;font-style:normal;font-weight:200;font-stretch:normal;
}

@font-face {
font-family:"roc-grotesk";
src:url("./fonts/roc-grotesk/roc-grotesk_900.woff2") format("woff2"),url("./fonts/roc-grotesk/roc-grotesk_900.woff") format("woff"),url("./fonts/roc-grotesk/roc-grotesk_900.otf") format("opentype");
font-display:auto;font-style:normal;font-weight:900;font-stretch:normal;
}

@font-face {
font-family:"roc-grotesk";
src:url("./fonts/roc-grotesk/roc-grotesk_300.woff2") format("woff2"),url("./fonts/roc-grotesk/roc-grotesk_300.woff") format("woff"),url("./fonts/roc-grotesk/roc-grotesk_300.otf") format("opentype");
font-display:auto;font-style:normal;font-weight:300;font-stretch:normal;
}

@font-face {
font-family:"roc-grotesk";
src:url("./fonts/roc-grotesk/roc-grotesk_500.woff2") format("woff2"),url("./fonts/roc-grotesk/roc-grotesk_500.woff") format("woff"),url("./fonts/roc-grotesk/roc-grotesk_500.otf") format("opentype");
font-display:auto;font-style:normal;font-weight:500;font-stretch:normal;
}

@font-face {
font-family:"roc-grotesk";
src:url("./fonts/roc-grotesk/roc-grotesk_400.woff2") format("woff2"),url("./fonts/roc-grotesk/roc-grotesk_400.woff") format("woff"),url("./fonts/roc-grotesk/roc-grotesk_400.otf") format("opentype");
font-display:auto;font-style:normal;font-weight:400;font-stretch:normal;
}

@font-face {
font-family:"roc-grotesk";
src:url("./fonts/roc-grotesk/roc-grotesk_100.woff2") format("woff2"),url("./fonts/roc-grotesk/roc-grotesk_100.woff") format("woff"),url("./fonts/roc-grotesk/roc-grotesk_100.otf") format("opentype");
font-display:auto;font-style:normal;font-weight:100;font-stretch:normal;
}

.tk-roc-grotesk { font-family: "roc-grotesk",sans-serif; }
