
#get-in-touch-section {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-height: 500px;
    color: white;
    background-color: rgba(16, 16, 16, 0.69);
    padding: 65px 0px;
}

#get-in-touch-1 {
    width: 50%;
    height: 50%;
    text-align: center;
    h2, a {
        padding: 0;
        margin: 0;
    }
    h2 {
        font-size: 52px;
    }
    p {
        font-family: roc-grotesk, sans-serif !important;
        padding: 23px 50px;
        display: inline-block;
        margin: 30px 0 0 0;
        color: white;
        box-shadow: 0 20px 38px rgba(0, 0, 0, 0.16)!important;
        border-radius: 5px;
    }
    p:hover, p:focus {
        transform: translateY(-0.25em);
    }
}

//Media display
@media (max-width:950px) {

    #get-in-touch-1 {
        width: 90%;
        height: 90%;
    }
}

