.latestNewsRoot {
    display: flex;
    justify-content: center;
    width: 80%;
    @media (min-width: 1000px){
        width: 100vw;
    }
    height: auto;
    flex-direction: column;
    margin: 90px auto 0 auto;
}

.latestTitle {
    width: 100%;
    height: auto;
    font-size: 40px;
    text-align: center;
}

.latestContent {
    display: flex;
    .container{
        .row{
            margin-left: calc(-1 * var(--bs-gutter-x));
        }
    }
}

.moreLinks {
    width: 80%;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 40px;
    color: #f2cc37;
    position: relative;
    padding-left: 40px;
}

.loadArrow{
    visibility: hidden;
    position: absolute;
    left: 16px;
    top: 0.4px;
    width: 15px;
    height: 15px;
    padding: 0;
}

@keyframes fadein {
    from{
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}


.exp-more-text:hover {
    color: #f2cc37 !important;
}