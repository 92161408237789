#cookie-banner{
    position: fixed;
    display:flex;
    justify-content:space-evenly;
    align-items: center;
    z-index: 2147483647;
    padding:2px 10px;
    bottom: 0px;
    width: 100%;
    background-color: white;
    p{
        width: 80%;
    }
    button{
        border:none;
        color:white;
        margin: 0 2% 0 0;
        padding: 10px;
        // height:30px;
        width: fit-content;
        white-space: nowrap;
    }

    #cookie-buttons  {
        display:flex;
        justify-content: space-around;
        width:15%;
    }

    #accept-cookies-button {
        background-color: #0026ff;
        // margin-left:10px;
    }

    #cookie-options-button {
        // margin-right:10px;
        background-color: gray;
    }

    span {
        color:blue;
        text-decoration: underline;
        cursor:pointer;
    }

    

    @media (max-width:950px) {

        p {
            width:65%;
        }

        #cookie-buttons  {
            // display:block;
            flex-direction: column;
            width: auto;
            align-items: end;
            padding: 0px 10px;
        }
        #accept-cookies-button {
            margin: 5px 0px 5px 0px;
        }
    
        #cookie-options-button {
            margin: 5px 0px 5px 0px;
        }
    }
}

.cds--modal-header {
    height:3rem;
}

.cds--modal-container--sm {
    background-color:black;
}
#options-modal{
    .cds--modal-container--sm{
        background-color: white;
    }
    .cookie-setting {
        display: flex;
        padding:10px 0;
        border-top:1px solid black;
        cursor:pointer
      }
    .cookie-description{
        margin-left: 200px;
        font-size: 16px;
        width: 100%;
    }
    .cds--toggle{
        display: flex;
        align-items: center;
    }
    .cds--toggle__switch--checked{
        background-color:#0026ff ;
    }
    .cds--toggle__text{
        width:0;
        font-size: 16px;
        font-weight: 600;
    }
    .cds--modal-header__heading{
        font-size: 30px;
    }
    .cds--modal-content{
        margin-bottom: 0;
    }
    .cds--modal-close{
        display: none;
    }
}
#save-cookies-button{
    border:none;
    background-color:#0026ff ;
    color:white;
    padding:5px 15px;
    border-radius: 4px;
    display: block;
    font-size: 16px;
    font-weight: bold;
    margin-left: auto;
    margin-bottom: 10px;

}
@media (min-width:42rem) {
    .cds--modal-container--sm {
        width: 100% !important;
    }
    .cds--modal-container {
        position: fixed !important;
        width: 100% !important;
        height: 100% !important;
        max-height: 100% !important;
    }
}
@media (min-width:950px) {
    .cds--modal-container--sm {
        width: 60% !important;
    }
    .cds--modal-container {
        position:static !important;
        width: 84% !important;
        height:auto !important;
        max-height:90% !important;
    }
}