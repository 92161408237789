.vid-root {
    background-color: rgba(0, 0, 0, 0.3);
    opacity: .7;
    position: relative;
    z-index: 1;
    pointer-events: none;
    // overflow:hidden;
    margin: 50px 0;
    @media (min-width:1000px) {
        width: 100vw;
    }
    @media (max-width:479px) {
    }
    .videoContainer {
        opacity: 0.5;
        width:100vw;
        // height: 100%;

        & .background-video{
            height: 100%;
            transition: all .25s ease-in-out;
            pointer-events: none;

            & video {
                transition: all .25s ease-in-out;
                pointer-events: none;
                @media (max-width: 690px) {
                    object-fit: cover;
                }
            }
        }
        iframe {
            transition: all .25s ease-in-out;
        }
    }
    .overlay {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 100;
        width: 100vw;
        pointer-events: none;
        height: 100%;

        .playArea {
            @media (max-width: 690px) {
                padding-top: 50px;
            }
        }
    } 
} 

.vid-root:hover {
    .playArea {
        .circle {
            transform: scale(1.1);
        }
        cursor:pointer;
    }
    .videoContainer {
        div{
            video {
                transform: scale(1.1);
            }
        }
        iframe {
            transform: scale(1.1);
        }
    }
}

.about-page-iframe {
    pointer-events: none;
    @media (min-width:480px) {
        width: 100vw !important;
        height: calc(30vw + 201.7px) !important;
    }
    
    @media (max-width:479px) {
        width: 100vw !important;
        height: calc(30vw + 245.5px) !important;
    }

}

.playArea {
    width: fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    pointer-events: auto;
} 

.modalContent {
    .video-player {
        width: 100% !important;
        height: 100% !important;
    }
}
.circle {
    height: 90px;
    width: 90px;
    border: 2px solid white;
    border-radius: 50%;
    position: relative;
    transition: all 0.3s ease-out;
}


.playButton {
    position: absolute;
    top: calc(50% - 8px);
    left: calc(50% - 8px);
    z-index: 2;
    fill: white;
}

.bottomText {
    margin-top: 10px;
    font-size: 30px;
    margin-right: 10px;
    margin-left: 10px;
    text-align: center;
    @media (min-width:376px) {
        font-size: 28px;
        margin-top: 18px;
    }
    @media (min-width:576px) {
        font-size: 30px;
        margin-top: 40px;
    }
}

.videoModal {
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.95);

    .cds--modal-close {
        background-color: transparent;
        svg {
            color: var(--bs-gray-300, #dee2e6);
            fill: var(--bs-gray-300, #dee2e6);
        }

        &:hover {
            background-color: transparent;

            svg {
                color: var(--bs-white, #fff);
                fill: var(--bs-white, #fff);
            }
        }
    }

    .cds--modal-container {
        height: 100% !important;
    }
}

.modalContent {
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.cds--modal-container--lg {
    width: 99vw !important;
}

.cds--modal-container {
    background-color: rgba(0, 0, 0, 0.75);
}
