.articleRoot {
}


.titleSection {
    width: 100vw;
    height: 550px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.articleTitleContainer {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .30);
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
}

.articleTitleCategory {
    text-align: center;
    box-sizing: border-box;
    vertical-align: baseline;
    font-family: roc-grotesk, sans-serif !important;
    font-weight: 600;
    border: 2px solid rgba(255,255,255,0.4);
    padding: 4px 10px;
    margin: 5px 6px 0px 5px;
    display: inline-block;
    font-size: 14px;
    line-height: 18px;
    color: #ffffff;
    border-color: rgba(255,255,255,0.4);
    -webkit-border-radius: 4px!important;
    transition: opacity .45s cubic-bezier(0.25, 1, 0.33, 1), transform .45s cubic-bezier(0.25, 1, 0.33, 1), border-color .45s cubic-bezier(0.25, 1, 0.33, 1), color .45s cubic-bezier(0.25, 1, 0.33, 1), background-color .45s cubic-bezier(0.25, 1, 0.33, 1), box-shadow .45s cubic-bezier(0.25, 1, 0.33, 1);
    transform: rotateX(0deg) translate(0px, 0px);
    opacity: 1;
}

.articleTitleCategory:hover {
    color: #fff;
    border-color: transparent;
    background-color: #f2cc37!important;
    transform: translateY(-3px)!important;
    box-shadow: 0 20px 38px rgba(0, 0, 0, 0.16)!important;
}

.title {
    display: block;
    width: 80%;
    color: white;
    text-align: center;
}

.titleContent {
    width: 900px;
    font-size: 62px;
    line-height: 70px;
}

.contentSection {
    width: 100vw;
    display: flex;
    justify-content: center;
    padding-bottom: 150px;
}

.content {
    width: 80%;
    max-width: 900px;
    display: block;
    text-align: center;
    color: white;
}

.contentSubTitle {
    margin-top: 70px;
    margin-bottom: 20px;
    font-size: 34px;
    line-height: 44px;
    font-weight: 400;
}

.articleParagraph {
    line-height: 26px;
    text-align: left;
    margin-bottom: 30px;
    font-size:18px;
}

.nextSection {
    width: 100vw;
    height: 300px;
    background-size: 100%;
    background-position: center;
    text-decoration: none;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-size 0.30s ease-out;
}


.nextSection:hover {
    background-size: 115%;
}

.nextSectionContainer{
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.30);
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    transition: all 1s ease-out;

}

.nextSectionContainer:hover {
    background-color: rgba(0, 0, 0, 0);
}

.nextContent {
    background: #000000;
    padding: 15px;
}

.inContentLink {
    text-decoration: none;
    color: #f2cc37;
    transition: color 0.3 ease;
}

.inContentLink:hover {
    color: white;
}

#first-movers-video {
    opacity: 1; 
    visibility: visible; 
    width: 100%; 
    height: auto; 
    aspect-ratio: 16 / 9;
}

.tenet-li {
    padding-left: 10px;
}

@media screen and (max-width: 999px) {
    .titleContent {
        font-size: 43px;
        line-height: 40px;
    }

    .titleSection {
        height: auto;
    }

    .title {
        margin: 10% 0px;
    }

    .content {
        width: 65%;
    }
}

@media screen and (max-width: 690px) {
    .titleContent {
        font-size: 38px;
    }
    .title {
        width: 88%;
    }
    .content {
        width: 70%;
    }
}