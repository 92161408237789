#about-page{
    color:white;
    background-color: black;
    padding: 0px 20px;
    
    #about-page-transforming {
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgb(0, 0, 0);
        width: 100%;
        height: 100%;
        @media (max-width:950px) {
            p {
                width: 80%;
            }
        }
        p {
            width: 55%;
            display: inline-block;
            font-weight: 400;
            text-align: center;
            font-size: 18px;
            @media (min-width:1466px) {
                width: 65%;
            }
            @media (min-width:1250px) and (max-width:1466px){
                width: 65%;
            }
            @media (min-width:1000px) and (max-width:1249px){
                width: 75%;
            }
            @media (max-width:999px) {
                width: 100%;
            }
        }
        @media (min-width:1466px) { 
            padding: 8vw calc(50vw - 632.5px) 5vw calc(50vw - 622.5px);
        }
    
        @media (max-width:1465px) and (min-width:1000px) { 
            padding: 8vw 100px 8vw 110px;
        }
        @media (min-width:691px) and (max-width:999px) {
            padding: 10vw calc(50vw - 300px); 
        }
        @media (min-width:480px) and (max-width:690px) {
            padding: 10vw calc(50vw - 210px);
        }
        @media (max-width:479px) and (min-width:322px) { 
            padding: 20vw calc(50vw - 160px);
        }
        @media (max-width:321px) { 
            padding: 20vw 0;
        }
    }

    #about-page-today {
        display: flex;
        background-color: black;
        width: 100%;
        p {
            width: 80%;
            text-align: left;
            font-size: 18px;
        }
        @media (min-width:1466px) { 
            padding: 100px calc(50vw - 632.5px) 5px calc(50vw - 622.5px);
        }
    
        @media (max-width:1465px) and (min-width:1000px) { 
            padding: 80px 100px 80px 110px;
        }
    
        @media (min-width:0px) and (max-width:999px) {
            max-width: 100%;
            padding: 20px 0 50px 0;
            display: flex;
            flex-direction: column;
        }
        @media (min-width:691px) and (max-width:999px) {
            padding: 50px calc(50vw - 300px); 
        }
        @media (min-width:480px) and (max-width:690px) {
            padding: 30px calc(50vw - 210px);
        }
        @media (max-width:479px) and (min-width:322px) { 
            padding: 20px calc(50vw - 160px);
        }
        @media (max-width:321px) and (min-width:300px) { 
            padding: 20px calc(50vw - 150px);
        }
        @media (max-width:299px) { 
            padding: 0px;
        }
    }

    #about-page-atrhombus {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;   
        width: 100%;
        background: linear-gradient(to right, rgba(7, 7, 7, 0.881), rgba(0, 0, 0, 0.117)), url('../assets/img/AdobeStock_299561221.jpeg');
        background-size: cover;
        z-index: -1;
        @media (min-width:1466px) { 
            padding: 100px calc(50vw - 632.5px) 100px calc(50vw - 622.5px);
        }
    
        @media (max-width:1465px) and (min-width:1000px) { 
            padding: 80px 100px 80px 110px;
        }
        @media (min-width:691px) and (max-width:999px) {
            padding: 50px calc(50vw - 300px); 
        }
        @media (min-width:480px) and (max-width:690px) {
            padding: 50px calc(50vw - 210px);
        }
        @media (max-width:479px) and (min-width:322px) { 
            padding: 30px calc(50vw - 160px);
        }
        @media (max-width:321px) and (min-width:300px) { 
            padding: 20px calc(50vw - 150px);
        }
        @media (max-width:299px) { 
            padding: 0px;
        }
        h2 {
            @media (max-width:950px) {
            width: 100%;
            max-width: 80%;
            }
        }

        .about-page-atrhombus-text-cont {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 10px;
            text-align: center;
            width: 65%;
            @media (min-width:1000px) { 
                width: 65%;
            }
            
            @media (max-width:999px) { 
                width: 100%;
            }
            h2 {
                font-weight: 600;
            }
        }
    }

    #about-page-innovators {
        background: linear-gradient(to right, rgb(7, 7, 7), rgba(46, 46, 46, 0.174)), url('../assets/img/AdobeStock_225528986.jpeg');
        background-size: cover;
        width: 100%;
        aspect-ratio: 1.95 / 1;
        padding: 45px 100px;
        display: grid;
        grid-template-columns: repeat(auto-fill, 50%);
        @media (min-width:1466px) { 
            aspect-ratio: 2.6/1;
            padding: 0 calc(50vw - 632.5px) 0 calc(50vw - 622.5px);
        }
    
        @media (max-width:1465px) and (min-width:1000px) { 
            padding: 50px 100px 50px 110px;
        }
    
        @media (min-width:0px) and (max-width:999px) {
            padding: 20px 0 50px 0;
            display: flex;
            flex-direction: column;
        }
        @media (min-width:691px) and (max-width:999px) {
            padding: 50px calc(50vw - 300px); 
        }
        @media (min-width:480px) and (max-width:690px) {
            padding: 30px calc(50vw - 210px);
        }
        @media (max-width:479px) and (min-width:322px) { 
            padding: 20px calc(50vw - 160px);
        }
        @media (max-width:321px) and (min-width:300px) { 
            padding: 20px calc(50vw - 150px);
        }
        @media (max-width:299px) { 
            padding: 0px;
        }

        #about-page-innovators-text {
            display: flex;
            flex-direction: column;
            justify-content: center;
            h2, p, span {
                display: block;
                width: 100%;
                text-align: left;
                float: left;
            }
            h2 {
                margin: 0 0 7px 0;
                font-size: 34px;
            }
            p {
                font-size: 18px;
                padding: 0;
            }
            a {
                color: white;
            }
        }
    }
}

//Media display 0px-950px WIDTH
@media (max-width:950px) {
    #about-page-innovators-cont {
        display: inline-block;
        width: 80%;
    }
    #about-page-innovators-text {
        margin: 0;
    }
    #about-page-atrhombus {
        h2 {
            width: 100%;
            max-width: 80%;
        }
    }
    #about-page {
        padding: 0 2px;
    }
    .about-page-atrhombus-cont {
        padding: 100px 0;
    }
}

//Media display 2000px-5000px WIDTH
@media (max-width:5000px) {
    #about-page-atrhombus {
        aspect-ratio: 3 / 1;
    }
    #about-page-atrhombus-cont-center {
        padding: 20px 5px;
    }
}

.loader-line-about {
    position: absolute;
    left: 0;
    top: 40%;
    width: 25px;
    height: 2px;
    overflow: hidden;
    background-color: #000;
    margin: 0;
}

.moreLinks:hover {
    .loader-line-about:before {
        content: "";
        position: absolute;
        left: 0;
        top: 35%;
        height: 2px;
        width: 25px;
        background-color: #ffffff;
        -webkit-animation: lineAnim 0.6s linear normal;
        -moz-animation: lineAnim 0.6s linear normal;
        animation: lineAnim 0.6s linear normal;
        animation-iteration-count: 1;
        animation-timing-function: cubic-bezier(.2,.8,.76,.63);
        border-radius: 20px;
    }
    .loader-line{
        visibility: hidden;
    }
    .loadArrow {
        visibility: visible;
        animation: fadein 0.7s ease-in-out;
    }
}

.loadArrow{
    visibility: hidden;
    position: absolute;
    left: 16px;
    top: 0.4px;
    width: 15px;
    height: 15px;
    padding: 0;
}


@keyframes lineAnim {
    0% {
        left: -40%;
    }
    25% {
        left: 0%;
        width: 100%;
    }
    50% {
        left: 50%;
        width: 80%;
    }
    75% {
        left: 100%;
        width: 80%;
    }
    100% {
        left: 0;
        width: 100%;
    }
}

@keyframes fadein {
    from{
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}