#contact-page{
    background-image: url('../assets/img/AdobeStock_299561221.jpeg');
    background-size: cover;
    background-position: center top;
    font-family: roc-grotesk, sans-serif!important;
    color:white;

    h2 {
        padding-top:100px;
        text-align: center;
        line-height: 44px;
        font-size: 34px;
        font-weight: 400;
    }

    p{
        text-align: center;
    }
    form{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width:60%;
        margin: auto;
        min-height:482px;
    }
    input{
        margin-bottom: 6px;
        padding: 12px;
        background-color: black;
        opacity: 0.85;
        color: white;
        border: solid 1px gray;
    }
    input::placeholder{
        color:white;
    }
    textarea{
        min-height: 290px;
        padding: 10px;
        background-color: black;
        opacity: 0.85;
        color: white;
        border: solid 1px gray;
    }
    textarea::placeholder{
        color:white
    }
    textarea:focus{
        outline:2px solid #0f62fe;
        outline-offset: -2px;
    }
    button{
        text-align: center;
        border:none;
        height: 50px;
        background-color: #0026ff;
        color:white;
        font-weight: bold;
        font-size: 22px;
        margin-top: 4px;
    }

    button:disabled{
        background-color: gray;
    }
    .cds--form-requirement{
        font-size: 14px;
        margin: 0;
        padding: 0;
        font-weight: 600;   
    }

    .loading-container {
        display:inline-flex;
        justify-content: center;
        line-height:1rem;
    }

}
#contact-page-content{
    margin: 0 auto;
    max-width: 1425px;
}
#header{
    h1{
        position: relative;
        margin-left: 15%;
        font-weight: 400;
        font-size: 62px;
        top:100px;
        animation: slideUpHeader ease .8s forwards;
    }
    background-color: black;
    overflow: hidden;
    position: relative;
    height: 200px;
    display: flex;
    align-items: center;
    // h1{
    //     
    //     margin-left:100px;
    // }
}

#contact-description {
    p {
        font-size: 18px;
        font-weight: 400;
    }
    margin-bottom:30px;
    position: relative;
    overflow: hidden;
}

#address-grid{
    font-weight: 400px;
    display: flex;
    justify-content: space-around;
    width: 66.6%;
    text-align: center;
    margin-top:20px;
    margin:50px auto 0;
    padding-bottom: 50px;
    p{
        font-size: 16px;
        padding-bottom: 10px;
    }
    b{
        font-weight: bold;
        font-size: 18px;
        padding-bottom: 10px;
    }
    div {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
}
#contact-slide-in{
    position: relative;
    top:100px;
    animation: slideUpHeader ease .8s forwards;
    padding: 0 20px
}

@media (max-width:760px) {
    #address-grid{
        flex-direction: column;
    }
    #contact-header{
        h1{
            font-size: 40px;
        }
    }
    .address {
        padding-bottom: 30px;
    }
}
@keyframes slideUpHeader {
    0% {transform: translateY(0);}
    100% {transform: translateY(-100px);opacity:1;}
  }
  