#header{
    width: 100%;
    max-height: 500px;
    position: relative;
    display: flex;
    align-items: center;
    padding: 10px 0;
    h1 {
        position: relative;
        top:100px;
        animation: slideUpHeader ease .8s forwards;
        font-weight: 400;
        font-size: 62px;
        margin-left: 14%;
    }
}

@keyframes slideUpHeader {
    0% {transform: translateY(0);}
    100% {transform: translateY(-100px);opacity:1;}
}

@media screen and (max-width:950px) {
    #header{
        h1 {
            font-size: 40px;
            margin-left: 10%;
        }
    }
}
