.carouselRoot {
    width: 100%;
    aspect-ratio: 4 / 2;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-x: hidden;
    height: auto;
    background-image: url(../../assets/img/minuteman.jpeg);
    background-size: cover;
    background-position: center;

    @media (min-width: 1465px) {
        margin: 62.25px 0;
        padding: 0 calc(50vw - 632.5px) 0 calc(50vw - 622.5px);
        height: 645px; 
        width: 100%;
        max-height: 799px;
        #swiperContainer .swiper .swiper-wrapper{
            height: 338.156px;
            width: 1297.45px;
        }
    }
    @media (min-width:1000px) and (max-width:1465px) {
        margin: calc(5vw - 11px) 0 calc(11vw - 11px) 0;
        padding: 0 100px 0 91px;
    }

    #swiperContainer{
        width:100%;
        .swiper {
            width: 100%;
            display: flex;
            height: 100%;
            flex-direction: column;
            justify-content: space-between;
            .swiper-wrapper{
                height: 40%; 
                width: 85%; 
                align-items: center;
                .cardRoot {
                    height: 100%;
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    .cardImage {
                        height: 100%;
                        display: flex;
                        align-items: center;
                        overflow: hidden;
                        border-radius: 10px;
                        transition: all 0.4s ease-out;
                        @media (min-width:1000px) and (max-width:1465px) {
                            width: 100%;
                        }
                        width: 50%;
                        .image-holder {
                            border-radius: 10px;
                            filter: grayscale(100%);
                            transition: all 0.4s ease-out;
                            height: auto;
                            @media (min-width: 1465px) {
                                width: 100%
                            }
                            @media (min-width: 1000px) and (max-width:1465px) {
                                width: 100%
                            }
                            @media (max-width:999px) {
                                width: 100%;
                            }
                        }
                    }
                    .cardImage:hover {
                        transform: scale(0.97);
                        
                        .image-holder {
                            filter: grayscale(0%);
                            transform: scale(1.2);
                        }
                    }

                    .cardContainer {
                        width:100%;
                        padding:3px 217px 42px 20px;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        flex-grow: 1;
                        margin-left: 15px;
                        @media (min-width:1465px) {
                            width: 738.461px;
                        }

                        #cardTitle{
                            margin-bottom: 7px;
                            font-weight:400;
                        }
                        #cardSubtitle{
                            margin-bottom: 34px;
                            font-size:22px;
                        }
                        #cardDescription{
                            font-size:18px;
                            margin-bottom: 24px;
                            width:135%;
                        }
                        .linkText {
                            font-size:16px;
                            text-decoration: none;
                            color: white;
                            position: relative;
                        }

                        .linkText:hover {
                            color: white;
                        }

                        .linkText:after {
                            content: '';
                            display: block;
                            position: absolute;
                            width: 100%;
                            height: 2px;
                            padding-top: 3px;
                            border-bottom: 2px solid white;
                            transition: transform 0.4s cubic-bezier(0.23, 0.46, 0.4, 1);
                            -webkit-transition: -webkit-transform 0.4s cubic-bezier(0.23, 0.46, 0.4, 1);
                            animation: none;
                            transform-origin: left;
                        }
                        .linkText:hover:after {
                            animation: none;
                            transform: scaleX(0);
                            transform-origin: right;
                        }
                    }
                }
            }
            .swiper-pagination-bullets{
                position: relative;
                text-align:right;
                // @media (min-width:1000px) {
                //     padding: 0 calc(3.19vw - 4.89px) calc(3.19vw - 4.89px) calc(3.19vw - 4.89px); 
                // }
                // @media (max-width:999px) {
                //     padding: 0 20px 20px 20px
                // }
                .swiper-pagination-bullet {
                    margin: 5px 14px !important;
                    background-color: #fff;
                    padding: 4px;
                    position: relative;
                    opacity: 1;
                    .circular-loader {
                        animation-iteration-count: 1;
                        background-color: none;
                        width: 25px;
                        height: 25px;
                        // left: 80;
                        position: absolute;
                        left: -8.5px;
                        top: -8.5px;
                        
                        .loader-path {
                            fill: none;
                            stroke-width: 4px;
                            stroke-linecap: round;
                            stroke: rgba(0, 0, 0, 0);
                        }
                    }
                }
                
                .swiper-pagination-bullet-active {
                    background-color: #fff;
                    .circular-loader {
                        stroke: #ffffff;
                        animation-iteration-count: 1;
                        animation: animate-stroke 5s ease-out normal;
                        .loader-path {
                            stroke: #fff;
                        }
                    }
                    
                }
            }
        }
    }
}

.listRoot-temp {
    width: 100vw;
}

.listRoot {
    width: 100vw;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-image: url(../../assets/img/minuteman.jpeg);
    background-size: cover;
    background-position: center;
    padding-top: 20px;
    .listItem{
        width: 80%;
        margin: 24px 0px;
        .itemImage {
            width: 100%;
            overflow: hidden;
            border-radius: 15px;
            .item-image-holder {
                filter: grayscale(100%);
                transition: all 0.4s ease-out;
                height: 100% !important;
                width: 100% !important
            }
        }
        .itemImage:hover {
            .item-image-holder {
                filter: grayscale(0%);
                transform: scale(1.2);
            }
        }
        .itemDescription {
            margin-top: 10px;
        }
    }
}

@keyframes animate-stroke {
    0% {
        stroke-dasharray: 0, 1000;
    }
    50% {
        stroke-dasharray: 500, 1000;
    }
    100% {
        stroke-dasharray: 1000, 1000;
    }
}