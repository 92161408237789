.accordion-item {
    background-color: transparent;
    display: flex;
    border: none;
    width: 100%;
    flex-direction: column;
    font-size: 18px;
}
.accordion-img-div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

#accordion-list-cont {
    display: grid;
    grid-template-columns: 45% 55% ;
    width: 100%;
    margin-bottom: 20px;
    @media (min-width:0px) and (max-width:999px) {
        max-width: 90%;
        display: flex;
        flex-direction: column;
        margin: 0 auto 20px auto;
        
        p, h3 {
            text-align: left;
            float: center; 
            width: 98%;
        }
        .p1 {
            margin: 20px 0 0 0;
        }
    }
}

.accordion-title {
    width: fit-content;
    display: inline-block;
    color: white;
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: 400;
    transform: color 0.5s;

    @media (max-width:999px) {
        width: fit-content !important;
        margin: 30px 0 0 0;
    }
}
.accordion-title:hover {
    color: #F2CC37;
    transition: color .6s;
}

.accordion-title::after {
    content: '';
    display: block;
    width: 0;
    margin-bottom: -2px;
    border-bottom: solid 2px #F2CC37;
    
    transition: width .6s;
    transition-timing-function: cubic-bezier(0.3, 0.4, 0.1, 1);
}
.accordion-title:hover::after {
    width: 100%;
    margin-left: center;
}


.accordion-content {
    overflow-y: hidden;
    
    font-weight: 400;
    p {
        line-height: 30px;
        width: 90%;
        font-size: 18px !important;
    }
    .p1 {
        margin: 5px 0 0 0 ;
        @media (min-width:691px) and (max-width:999px) {
            margin: 0 0 20px 0;
        }
    }
    .p2 {
        margin: 0 0 50px 0;
        @media (min-width:691px) and (max-width:999px) {
            margin: 0 0 20px 0;
        }
    }

}

.active {
    max-height: 1000px;
    transition: max-height .6s ease-in-out;
}

.closed {
    max-height: 0px;
    transition: max-height .6s ease-in-out;
}

.highlight {
    color: #F2CC37;
    border-bottom:2px solid #F2CC37;
    display: inline-block;
}

.nohighlight {
    color: white;
    border-bottom:2px solid #323232;
}

#accordion-left-img {
    display: block;
    background-image: url('../../assets/img/people-in-a-technology-cyber-space-meeting-P5NT3P8-2.jpeg');
    background-size: cover;
    background-position: center center;
    width: 100%;
    height: 100%;
    aspect-ratio: 1 / 1;

    @media (max-width:999px) {
        max-height: 400px;
    }
}