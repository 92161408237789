#thankyou-page{
    font-family: roc-grotesk, sans-serif!important;
    color:white;

    h2 {
        padding-top:100px;
        text-align: center;
        line-height: 44px;
        font-size: 34px;
        font-weight: 400;
        margin: 0px 50px 0px 50px;
    }

    p{
        text-align: center;
    }
    form{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width:60%;
        margin: auto;
        min-height:482px;
    }
    input{
        margin-bottom: 6px;
        padding: 12px;
        background-color: black;
        opacity: 0.85;
        color: white;
        border: solid 1px gray;
    }
    input::placeholder{
        color:white;
    }
    textarea{
        min-height: 290px;
        padding: 10px;
        background-color: black;
        opacity: 0.85;
        color: white;
        border: solid 1px gray;
    }
    textarea::placeholder{
        color:white
    }
    button{
        text-align: center;
        border:none;
        height: 50px;
        background-color: rgb(242, 204, 55);
        color:white;
        font-weight: bold;
        font-size: 22px;
        margin-top: 4px;
    }

    button:disabled{
        background-color: gray;
    }
    .cds--form-requirement{
        font-size: 14px;
        margin: 0;
        padding: 0;
        font-weight: 600;   
    }

    .thankyouMessage {
        text-align: center;
        vertical-align: middle;
        line-height: 500px;
    }

}
#contact-page-content{
    margin: 0 auto;
    max-width: 1425px;
}
#header{
    h1{
        position: relative;
        margin-left: 15%;
        font-weight: 400;
        font-size: 62px;
        top:100px;
        animation: slideUpHeader ease .8s forwards;
    }
    background-color: black;
    overflow: hidden;
    position: relative;
    height: 200px;
    display: flex;
    align-items: center;
    // h1{
    //     
    //     margin-left:100px;
    // }
}

#contact-description {
    p {
        font-size: 18px;
        font-weight: 400;
    }
    margin-bottom:30px;
    position: relative;
    overflow: hidden;
}


#contact-slide-in{
    position: relative;
    top:100px;
    animation: slideUpHeader ease .8s forwards;
    padding: 0 20px;
}

@keyframes slideUpHeader {
    0% {transform: translateY(0);}
    100% {transform: translateY(-100px);opacity:1;}
  }
  