.video-controller {
    position: relative;
    height: 100%;

    .video-player {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }

    .hidden {
        .volume-rocker {
            display: none;
        }
    }

    .controller {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        opacity: 0;
        pointer-events: none;
        transition: opacity 1s ease-out, padding 0s ease;
        background-color: rgba(0, 0, 0, 0.4);
        border-radius: 12px;
        padding-left: 0rem;
        padding-right: 0rem;

        &.fullscreen {
            margin-bottom: 3rem;
        }

        @media (min-width: 950px) {
            padding-left: 1.5rem;
            padding-right: 1.5rem;
        }

        & .progress-range {
            width: 100%;
            & input {
                padding: 0;
            }
        }

        & .volume-rocker {
            transition: all 1s ease-out;
            & input {
                padding: 0;
            }
        }

        .progress-time {
            color: white;
        }

        & button {
            color: white;

            & svg {
                fill: white !important;
            }

            &:hover {
                background-color: black;
            }

            &.cds--overflow-menu--open {
                background-color: transparent !important;
            }
        }

        p {
            font-family: Helvetica, Arial, sans-serif;
            font-size: 1rem;
        }

        input[type='range'] {
            -webkit-appearance: none;
            appearance: none;
            width: 100%;
            cursor: pointer;
            outline: none;
            overflow: hidden;
            border-radius: 16px;
            height: 12px !important;
            border: none !important;
        }

        input[type='range']::-webkit-slider-runnable-track {
            height: 12px;
            background: #ccc;
            border-radius: 16px;
        }

        input[type='range']::-moz-range-track {
            height: 12px;
            background: #ccc;
            border-radius: 16px;
        }

        input[type='range']::-webkit-slider-thumb {
            -webkit-appearance: none;
            appearance: none;
            height: 12px;
            width: 12px;
            background-color: #fff;
            border-radius: 50%;
            border: 0px solid var(--bs-primary, #0d6efd);
            box-shadow: calc(-100vw - 6px) 0 0 100vw var(--bs-primary, #0d6efd);
        }

        input[type='range']::-moz-range-thumb {
            height: 12px;
            width: 12px;
            background-color: #fff;
            border-radius: 50%;
            border: 0px solid var(--bs-primary, #0d6efd);
            box-shadow: calc(-100vw - 6px) 0 0 100vw var(--bs-primary, #0d6efd);
        }
        input:focus {
            outline: none;
        }
        input:focus-visible {
            outline: auto;
        }
    }

    .active {
        opacity: 1;
        pointer-events: all;
    }

    .video-action {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 80px;
        height: 80px;
        transform: translate(-50%, -50%);
        opacity: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        pointer-events: none;
        background-color: rgba(0, 0, 0, 0.8);
        border-radius: 50%;
        transition: all 0.25s ease-in-out;
        color: white;
        fill: white;
        z-index: 10;

        &.active {
            opacity: 0.7;
        }
    }
}

.controller-options {
    z-index: 9001;

    & button {
        & svg {
            fill: white !important;
        }
    }
    &.cds--overflow-menu-options::after {
        background-color: unset;
    }
    .playback-active {
        color: var(--bs-primary, #0d6efd);
    }
}

.interact-label-container {
    position: relative;

    .interactive-label {
        position: absolute;
        background-color: lightcoral;
        padding: 1.5rem;
        bottom: 0;
    }
}
