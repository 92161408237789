header {
    background-color: black;
    @media (max-width:999px) {    
        img {
            width: 10rem !important;
            height: 3.5rem !important;
        }
        position: sticky;
        top: 0px;
        z-index: 102;

    }

    @media (min-width:1000px) {
        img {
            width: 14rem;
            height: 5rem;
        }
    }
}

body{
    overflow-x: hidden !important;
    font-family: roc-grotesk, sans-serif;
    font-style: normal;
    font-weight: 400;
}

footer{
    background-color: black;
    color:white;
    
    a{
        text-decoration: none;
        color:white
    }
    a:hover{
        color:white;
        opacity: 0.65;
        transition: opacity 0.2s ease;
    }
}

.loader-line {
    height: 1px;
    width: 25px !important;
    position: absolute;
    left: 0;
    top: 45%;
}

.header-content {
    margin-right: 0px;
    display: flex;
    justify-content: space-between;

    @media (min-width:1466px) { 
        height: 121px;
        padding: 0 calc(50vw - 632.5px) 0 calc(50vw - 622.5px);
    }

    @media (max-width:1465px) and (min-width:1000px) { 
        height: 121px;
        padding: 0 100px 0 110px;
    }

    @media (max-width:999px) and (min-width:691px) { 
        padding: 12px calc(50vw - 300.5px);
    }

    @media (max-width:690px) and (min-width:480px) { 
        padding: 12px calc(50vw - 210px);
    }

    @media (max-width:479px) and (min-width:322px) { 
        padding: 12px calc(50vw - 160px);
    }

    @media (max-width:321px) and (min-width:300px) { 
        padding: 12px calc(50vw - 150px);
    }

    @media (max-width:299px) { 
        padding: 12px 0px;
    }

}

.header-logo {
    @media (max-width:999px) {
        height: 45px;
        display: inline-block;
        align-items: center; 
    }
    @media (min-width:1000px)  {
        margin:28px 0;
        align-items: center;
    }
    cursor: pointer
}

.moreLinks:hover {
    .loader-line-home:before {
        content: "";
        position: absolute;
        left: 0;
        top: 35%;
        height: 2px;
        width: 25px;
        background-color: #f2cc37;
        animation: lineAnim 0.45s cubic-bezier(0.23, 0.46, 0.4, 1) normal;
        animation-iteration-count: 1;
        border-radius: 20px;
    }
    .loader-line{
        visibility: hidden;
    }
    .loadArrow {
        visibility: visible;
        animation: fadein 0.7s ease-in-out;
    }
    .loadArrow + span {
        animation: moveRight 0.45s cubic-bezier(0.23, 0.46, 0.4, 1) forwards; 
    }
}

#root{
    max-width: 100%;
    width: 100%;
}

#header-nav{
    display: flex;
    width: auto;
    align-items: center;

    a {
        margin-left: 10px;
        margin-right: 10px;
        color:white;
        text-decoration:none;
    }
    a.active-link{
        border-bottom:2px solid rgb(52, 82, 255);
    }
    li {
        font-size: 14px;
    }
    li::after {
        content: '';
        margin-top:3px;
        display: block;
        width: 0;
        height: 2px;
        background: rgb(52, 82, 255);
        transition: width .2s ease-in;
        transform: translate(10px, 0px);
    }
    li:hover::after {
        width: calc(100% - 20px);
        transform: translate(10px, 0px);
        margin-left: center;
    }

    @media (max-width:999px) {
        display:none;
    }
}

#header-hamburger{
    font-size:50px;
    height: 25px;
    width: 29px;
    background:black;
    color:white;
    border:none;
    margin-top:10.5px;

    .line{
        width:22px;
        border-top: 2px solid white;
        background:white;
        position:relative;
        margin-bottom:4px;    
    }
    .line+.line+.line {
        width: 1rem;
    }

    .crossLine{
        width: 19px;
        border-top: 2px solid white;
        background: white;
        transform: rotate(0.88turn) translate(1px, 2px);
    }
    .crossLine+.crossLine{
        width: 19px;
        border-top: 2px solid white;
        background: white;
        transform: rotate(0.12turn) translate(1px, -2px);
    }

    @media (min-width:1000px) {
        display:none;
    }
    @media (max-width:999px) and (min-width:691px) {
        display:block;
    }
}

#mobile-nav-modal{
    opacity: 0.93; 
    z-index: 101;
    position: fixed;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    width: 100vw;
    background-color: rgb(52, 82, 255, 0.93);
    font-family: roc-grotesk, sans-serif !important;
    font-weight:600;
    overflow-y: auto;

    li{
        width: fit-content;
        margin-bottom:8px
    }
    ul{
        padding-left:15px;
        padding-bottom:4px;
        display: none;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;

        @media (max-width:999px) and (min-width:691px) { 
            margin: 40px 0 60px;
        }
    }
    a{
        color:rgb(255,255,255,0.8);
        text-align:center;
        @media  (min-width:691px) {
            font-size:48px;
        }
        @media  (max-width:690px) {
            font-size:32px;
        }

    }
    a:hover{
        color:rgb(255,255,255,1);
    }
    a.active-link{
        color:rgb(255,255,255,1);
    }
}

#mobile-nav-modal.open-modal{
    height: 100vh;
    animation: slideDown 1.0s cubic-bezier(0, 0, .2, 1) 0s; 

    li{
        width: fit-content;
        margin:10px 0px;
        position: relative;
        top:200px;
        opacity: 0;
    }
    @for $i from 1 through 5 {
        li:nth-child(#{$i}n) {
            animation: slideUpFadeIn ease .4s forwards #{($i * 0.1)+.4}s;
        }
    }
    ul{
        display: flex;
    }
}

#mobile-nav-modal.close-modal{
    animation: fadeOut 1s cubic-bezier(.1, .1, 0.1, .9) 0s;
    li {
        animation: fadeOutText 2s cubic-bezier(.1, .1, 0.1, .9) 0s;
    }
}

#footer-nav{
    height: 170px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.18);
    flex-wrap: wrap;

    a{
        margin: 0 20px;
        font-size: 14px;
    }

    @media (max-width: 500px) {
        height: 105px;
    }
}
#copyright{
    p{
        font-size: 14px;
    }
    height: 235px;
    background-color: black;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    align-items: center;
    text-align: center;
   
    #rhombus-linkedin-link{
        height: 50px;
        width: 50px;
        border-radius: 65%;
        border: 2px solid rgba(255, 255, 255, 0.18);
        display: flex;
        justify-content: center;
        align-items: center;
        transition: border 0.3s ease;
    }
    #rhombus-linkedin-link:hover{
        border:2px solid rgba(255, 255, 255, 1);
        opacity:1;
        transition: opacity 0.2s ease;
    }
};

@keyframes slideUpFadeIn {
    0% {transform: translateY(0);}
    100% {transform: translateY(-200px);opacity:1;}
  }
@keyframes fadeOut {
    0% { opacity: 1; height: 100%;}
    99% { opacity: 0.01;width: 100%; height: 100%;}
    100% { opacity: 0; height: 0;}
}
@keyframes fadeOutText {
    0% { opacity: 1;}
    50% { opacity: 0.3;}
    100% { opacity: 0; }
}

@keyframes slideDown {
    0% {height:0vh}
    100% {height: 100vh}
}

@keyframes lineAnim {
    0% {
        left: -40%;
    }
    25% {
        left: 0%;
        width: 100%;
    }
    50% {
        left: 50%;
        width: 80%;
    }
    75% {
        left: 100%;
        width: 80%;
    }
    100% {
        left: 0;
        width: 100%;
    }
}

@keyframes moveRight {
    0% {} 
    100% {transform: translateX(5px);}
}
