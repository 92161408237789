a {
    text-decoration: none;
}

#inthenewsContainer {
    width: 80%;
    height: auto;
}

.articleContainer {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    height: 220px;
    margin-bottom: 35px;
    border-radius: 5px;
}

.articleContainerRow {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
    border-radius: 5px;
}

.articleContainer:hover .articleImgContainer {
    transform: translate(0px, -10px);
}

.articleContainerRow:hover .articleRowImgContainer {
    transform: translate(0px, -10px);
}

.titleContainer {
    width: 100%;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    color:white;
    background-color: #000000;
    margin-bottom: 45px;
}

.inthenewstitle {
    width: 80%;
    font-size: 45px;
    // font-weight: bold;
}

.articleImgContainer {
    height: 220px;
    width: 280px;
    transition: transform 0.25s ease-out;
    z-index: 1;
}

.articleRowImgContainer {
    height: 220px;
    width: 100%;
    transition: transform 0.25s ease-out;
}

.articleMetadataContainer {
    flex-grow: 1;
    height: 220px;
    width: 75%;
}


.articleRowMetadataContainer {
    flex-grow: 1;
     @media (max-width: 1000px){
        padding-bottom: 40px;
    }
}

.metaContent {
    // width: 60%;
    height: 100%;
    padding-left: 45px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
}

.metaContentRow {
    width: auto;
    // padding-left: 45px;
    margin-top: 10px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
}

.articleCategory {
    color: #f2cc37;
    font-size: 14px;
    font-weight: bold;
    padding-bottom: 10px;
}

.articleDescription > a {
    color: #ffffff;
}

.articleDate {
    color: #ffffff;
    font-size: 13px;
    padding-top:15px;
}


@media screen and (max-width: 690px) {
    .articleContainer {
        flex-direction: column;
        height: auto;
        align-items: center;
        gap: 23px;
    }

    .articleMetadataContainer {
        width: 100%;
        height: unset;
    }

    .articleImgContainer {
        width: 100%;
    }

    #inthenewsContainer {
        width: 65%;
    }

    .metaContent {
        padding: 0px;
    }
    
}