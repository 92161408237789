#privacy-policy-page {
    color:white;
    background-color: black;
    
    margin-left:30px;
    margin-right:30px;
    display:flex;
    flex-direction:row;
    justify-content: center;
    
    .privacy-policy {
        max-width:720px;
    }

    

    p {
        padding-bottom: 1.5rem;
    }

    h3 {
        padding-bottom: 1.5rem;
        // font-weight: 500;
    }

    a {
        color:inherit;
    }
    a:hover {
        text-decoration: underline;
    }

    ul {
        list-style-type : disc;
        padding-bottom:1.5rem;
        &:last-child {
            padding-bottom:0rem;
        }
        li{
            margin-left:2rem;
            padding-left: 1rem;
            padding-bottom: 1.5rem;
            &:last-child {
                padding-bottom:0rem;
            }
        }
    }

    ol {
        padding-bottom:1.5rem;
        &:last-child {
            padding-bottom:0rem;
        }
        list-style-type:decimal;
        li{
            margin-left:2rem;
            padding-left: 1rem;
            padding-bottom: 1.5rem;
            &:last-child {
                padding-bottom:0rem;
            }
        }
    }
    
    .privacy-policy-section {
        padding-bottom:2rem;
    }

    #last-updated {
        font-weight:400;
        padding-bottom: 2rem;
    }

    .in-short {
        font-style: italic;
    }

    .privacy-notice {
        padding-bottom: 1.5rem;
        font-size: var(--cds-body-02-font-size, 1rem)
    }

    #privacy-policy-table-of-contents {
        padding-bottom: 1.5rem;
        font-size: var(--cds-body-02-font-size, 1rem)
    }

    .list-items {
        padding-bottom: 1.5rem;
        font-size: var(--cds-body-02-font-size, 1rem)
    }
}