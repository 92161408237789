.em-animate {
    background-repeat: no-repeat;
    background-image: linear-gradient(to right,#0026ff 0,#0026ff 100%);
    background-size: 0% 80%;
    animation: highlight 0.9s cubic-bezier(.15,.75,.4,1) forwards 0.8s;
}

.loader-line-home {
    position: absolute;
    left: 0;
    top: 40%;
    width: 25px;
    height: 2px;
    overflow: hidden;
    background-color: #000;
    margin: 0;
}

.loadArrow{
    visibility: hidden;
    position: absolute;
    left: 16px;
    top: 0.4px;
    width: 15px;
    height: 15px;
    padding: 0;
}

.loadArrow-winning{
    visibility: hidden;
    position: absolute;
    left: 16px;
    top: 10px !important;
    width: 15px;
    height: 15px;
    padding: 0;
}

#home-page{
    color:white;
    background-color: #0A0A0A;
    padding: 0px 20px;
    @media (max-width:950px) {
        padding: 0;
    }
    @media (max-width:999px) {
        padding: 0;
    }
}

#home-header{
    h1{
        position: relative;
        font-weight: 400;
        font-size: 62px;
        @media (min-width:1466px) {
            width: calc(100vw - 438.4px);
            padding: 4vw calc(51vw - 629.805px) 4vw calc(49vw - 629.805px); 
        }

        @media (min-width:1000px) and (max-width:1465px) {
            padding: 4vw 90px;
        }
        @media (min-width:1332px) and (max-width:1465px) {
            width:calc(73vw - 75px);
        }

        @media (min-width:1307px) and (max-width:1331px) {
            width:calc(73vw - 35px);
        }

        @media (min-width: 1301px) and (max-width:1306px){
            width:calc(67vw);
        }

        @media (min-width:999px) and (max-width:1300px) {
            font-size:46.5px;
        }
        @media (min-width: 1288px) and (max-width:1300px) {
            width: 68vw;
        }

        @media (min-width: 1164px) and (max-width:1287px) {
            width: calc(74vw - 140px);
        }

        @media (min-width: 1137px) and (max-width:1163px) {
            width: calc(81vw - 147px);
        }

        @media (min-width: 1035px) and (max-width:1136px) {
            width:calc(82vw - 129px);
        }

        @media (min-width: 1000px ) and (max-width: 1034px) {
            width: calc(81vw - 129px);
        }

        @media (min-width: 691px) and (max-width:999px) {
            padding: 4vw calc(50vw - 300px);
            font-size: 43.4px;
        }

        @media (max-width:690px) {
            font-size: 40.3px;
        }
        @media (min-width:480px) and (max-width:690px) {
            width: 100vw;
            padding: 4vw calc(50vw - 210px);
        }

        @media (min-width:322px) and (max-width:479px) {
            padding: 4vw calc(50vw - 160px);
        }

        @media (max-width:321px) {
            padding: 4vw calc(50vw - 150px);
        }
    }
    em {
        font-style: normal;
    }
}

#home-page-americastronger {
    position: relative;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(to right, rgb(0, 0, 0), rgb(37, 37, 37));
    background-size: cover;
    display: grid;
    grid-template-columns: repeat(auto-fill, 50%);

    @media (min-width:1466px) { 
        padding: 8vw calc(46vw - 632.5px) 8vw calc(50vw - 629.5px);
    }

    @media (min-width:1000px) and (max-width:1465px) { 
        padding: 8vw 100px 8vw 84px;
    }

    @media (min-width:0px) and (max-width:999px) {
        max-width: 100%;
        display: flex;
        flex-direction: column;
    }
    @media (min-width:691px) and (max-width:999px) {
        padding: 4vw calc(50vw - 300px); 
    }
    @media (min-width:480px) and (max-width:690px) {
        padding: 4vw calc(50vw - 210px);
    }
    @media (max-width:479px) and (min-width:322px) { 
        padding: 4vw calc(50vw - 160px);
    }
    @media (max-width:321px) and (min-width:300px) { 
        padding: 4vw calc(50vw - 150px);
    }
    @media (max-width:299px) { 
        padding: 4vw 0px;
    }

    #home-page-americastronger-img-cont {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 0;
        @media (min-width: 1465px) {
            padding: 0 12.69px;
        }
        @media (min-width:1000px) and (max-width:1464px) {
            padding: 0 calc(1vw - 4.26px);
        }
        @media (max-width: 999px) {
            margin-bottom: 25px;
        }

        #home-page-americastronger-img {
            overflow:hidden;
            border-radius: 10px;
            div {
                background-image: url('../assets/img/AdobeStock_227700270.jpeg');
                background-size: cover;
                margin: 0;
                filter: grayscale(100%);
                background-position: center;
                transition: all 0.3s ease-out;
        
                @media (min-width:1465px) {
                    height: 342.734px;
                    width: 609.312px;
                    padding: 0 12.688px;
                }
                @media (min-width:1000px) and (max-width:1465px){
                    width: calc(50vw - 118.25px);
                    height: calc(30.96vw - 94.9px);
                }
                @media ( min-width: 691px) and (max-width:999px) {
                    width: 559.516px; 
                    height: 337.227px;
                }
                @media ( min-width: 480px) and (max-width:690px) {
                    width: 419.656px;
                    height: 236.055px;
                }
                @media ( min-width: 322px) and (max-width:479px) {
                    width: 319.75px;
                    height: 179.859px;
                }
                @media ( min-width: 300px) and (max-width:321px) {
                    width: 299.766px;
                    height: 168.617px;
                }
                @media (max-width: 300px) {
                    width: 99.9vw;
                    height: calc(50vw + 18px);
                }
            }
            div:hover {
                transform: scale(1.12);
                filter: grayscale(0%);
            }
        }
    }
    
    #home-page-americastronger-text-cont {
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        h2, p, span {
            display: block;
            text-align: left;
            float: left;
        }
        span {
            width: 50%;
        }
        h2 {
            line-height: 45px;
            margin-bottom: 32px;
            font-size: 34px;
            font-weight:400;
            width: 100%;
        }
        p {
            padding: 0;
            font-size: 18px;
            h4 { color: #F2CC37; }
            @media (min-width: 1425px) {
                width: 509.719px;
            }
            @media (min-width: 1000px) and (max-width: 1425px) {
                width: calc(40.95vw - 93.6px);
            }
            @media (min-width: 691px) and (max-width: 999px) {
                width: 559.562px;
            }
            @media ( min-width: 480px) and (max-width:690px) {
                width: 419.656px;
            }
            @media ( min-width: 322px) and (max-width:479px) {
                width: 319.75px; 
            }
            @media ( min-width: 300px) and (max-width:321px) {
                width: 299.766px;
            }
            @media (max-width:300px) {
                width: 99.9vw;
            }
        }
        a {
            color: white;
        }
        div {
            width: 100%;
        }
    
        @media (min-width: 1425px) {
            padding: 47px 48.8px 54.8px 73px;
        }
    
        @media (min-width: 1000px) and (max-width: 1424px) {
            padding: calc((101.5vw - 180px) * 0.04);
        }
        @media (min-width: 691px) and (max-width: 999px) {
            padding: 19.98px;
        }
    
        @media (max-width:999px) {
            div {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
            }
        }
    
        @media (max-width:950px) {
            display: flex;
            justify-content: center;
            align-items: center;
            p, h2 {
                text-align: left;
            }
        }
    
        @media (max-width: 690px) {
            padding: 0; 
        }
    }
}

//WinningComponent
#home-page-winning {
    position: relative;
    background: linear-gradient(to right, rgba(7, 7, 7, 0.881), rgba(0, 0, 0, 0.117)), url('../assets/img/iStock-1146898857.jpeg');
    background-size: cover;
    // aspect-ratio: 2.6 / 1;
    margin: 0;
    padding: 4vw 100px;
    position: relative;
    display: block;
    grid-template-columns: repeat(auto-fill, 50%);
    justify-content: center;
    align-items: center;
    @media (min-width:1466px) { 
        padding: 4vw calc(50vw - 632.5px) 4vw calc(50vw - 622.5px);
    }

    @media (max-width:1465px) and (min-width:1000px) { 
        padding: 4vw 100px 4vw 92px;
    }

    @media (min-width:0px) and (max-width:999px) {
        max-width: 100%;
        padding: 4vw 0;
        display: block;
        flex-direction: column;
    }
    @media (min-width:691px) and (max-width:999px) {
        padding: 4vw calc(50vw - 300px); 
    }
    @media (min-width:480px) and (max-width:690px) {
        padding: 4vw calc(50vw - 210px);
    }
    @media (max-width:479px) and (min-width:322px) { 
        padding: 4vw calc(50vw - 160px);
    }
    @media (max-width:321px) and (min-width:300px) { 
        padding: 4vw calc(50vw - 150px);
    }
    @media (max-width:299px) { 
        padding: 4vw 0; 
    }
}

#home-page-winning-text {
    padding: 0;
    h2, p, span {
        display: block;
        width: 100%;
        text-align: left;
        float: left;
        padding: 0;
    }
    h2 {
        padding: 0;
        line-height: 55px;
        margin: 0 0 7px 0;
        font-size: 34px;
    }
    p {
        padding-top: 15px;
        padding-bottom: 40px;
        font-size: 18px;
        h4 { color: #F2CC37; }
        span {
            margin-bottom: 27px;
        }
        span+span+span+span+span {
            margin-bottom: 0;
        }
    }
    a {
        color: white;
        margin-bottom: 8px;
    }
}

#home-page-joinus {
    background: linear-gradient(to right, rgba(7, 7, 7, 0.818), rgba(222, 222, 222, 0.174)), url('../assets/img/19-copy.jpeg');
    background-size: cover;
    background-position: center top;
    aspect-ratio: 4 / 1;
    display: block;
    height:35rem;
    grid-template-columns: repeat(auto-fill, 50%);
    justify-content: center;
    flex-direction: row;
    align-items: center;
    padding: 45px 100px;
    width: 100%;

    @media (min-width:1466px) { 
        padding: 100px calc(50vw - 632.5px) 100px calc(50vw - 622.5px);
    }

    @media (max-width:1465px) and (min-width:1000px) { 
        padding: 80px 100px 80px 110px;
    }

    @media (min-width:0px) and (max-width:999px) {
        padding: 20px 0 50px 0;
        display: block;
        flex-direction: column;
    }
    @media (min-width:691px) and (max-width:999px) {
        padding: 50px calc(50vw - 300px); 
    }
    @media (min-width:480px) and (max-width:690px) {
        padding: 30px calc(50vw - 210px);
    }
    @media (max-width:479px) and (min-width:322px) { 
        padding: 20px calc(50vw - 160px);
    }
    @media (max-width:321px) and (min-width:300px) { 
        padding: 20px calc(50vw - 150px);
    }
    @media (max-width:299px) { 
        padding: 0px;
    }
}

#home-page-joinus-text {
    h2, p, span {
        display: block;
        width: 100%;
        text-align: left;
        float: left;
    }
    h2 {
        margin: 0 0 30px 0;
        font-size: 34px;
    }
    p {
        font-size: 18px;
        h4 { color: #F2CC37; }
        padding: 0 0 20px 0;
    }
    a {
        color: white;
    }
}

#home-page-americastronger-text{
    @media (max-width:950px) {
        margin: 0 1%;
    }
    @media (max-width:999px) {
        margin: 0 1%;
    }
}

@keyframes highlight {
    0% {
        background-size: 0% 80%;
    }

    100% {
      background-size: 100% 100%;
    }
}

@keyframes slideUpHeader {
    0% {transform: translateY(0);}
    100% {transform: translateY(-100px);opacity:1;}
}

@keyframes fadein {
    from{
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes moveRight {
    0%{}
    100%{transform: translateX(5px);}
}